<template>
  <div class="game-container">
    <SpeakingSentence
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      @showSecondImg="showSecondImg"
      :isTwoImg="true"
      :lesson="45"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      titleInfo:{
        hanzi:"吃/喝……",
        pinyin:"chī / hē"
      },
      originGameList:[],
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-1-1.svg`),
          secondImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-1-2.svg`),
          pinyin: "chī dàngāo",
          hanzi: "吃蛋糕",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-2-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-2-2.svg`),
          pinyin: "chī mǐfàn",
          hanzi: "吃米饭",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-3-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-3-2.svg`),
          pinyin: "hē shuǐ",
          hanzi: "喝水",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-4-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-4-2.svg`),
          pinyin: "chī miàntiáo",
          hanzi: "吃面条儿",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-5-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-5-2.svg`),
          pinyin: "chī píngguǒ",
          hanzi: "吃苹果",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-6-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-46/image-6-2.svg`),
          pinyin: "hē niúnǎi",
          hanzi: "喝牛奶",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-46/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-46/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-46/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-46/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-46/menu-5.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-46/menu-6.svg"),
          startNumberRange: 6,
          endNumberRange: 6,
        },
      ],
    };
  },
  created() {
    this.originGameList = JSON.parse(JSON.stringify(this.gameList))

  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item,index) => {
        item.isShowSentence = false;
        item.bgImg = this.originGameList[index].bgImg
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
    showSecondImg(index) {
      this.gameList[index - 1].bgImg = this.gameList[index - 1].secondImg;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>