<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/background-laundry.svg"),
      gameIndex:46,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-basket.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-basket-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-detergent.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-detergent-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-hanger.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-hanger-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-iron.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-iron-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-machine.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-machine-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-wangou-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-46/stroke-wangou-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
