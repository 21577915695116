<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="45"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "真……",
        pinyin: "zhēn",
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/background-cats.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-1.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-1.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-2.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-2.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-3.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-3.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-4.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-4.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-1-story.svg"),
          id: 1,
          centencePinyin: "Yú zhēn xiǎo!",
          sentenceHanzi: "鱼真小！",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-2-story.svg"),
          id: 2,
          centencePinyin: "Nǐ tóufa zhēn cháng!",
          sentenceHanzi: "你头发真长！",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-3-story.svg"),
          id: 3,
          centencePinyin: "Nǐ zhēn gāo!",
          sentenceHanzi: "你真高！",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-46/cat-4-story.svg"),
          id: 4,
          centencePinyin: "Nǐ zhēn dà!",
          sentenceHanzi: "你真大！",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
