<template>
  <div class="game-container">
    <PinyinMoveGame
      :bgImg="bgImg"
      :durationArr="durationArr"
    />
  </div>
</template>

<script>
import PinyinMoveGame from "@/components/Course/GamePage/PinyinMoveGame";
export default {
  data() {
    return {
      lessonNum:46,
      bgImg: require("@/assets/img/16-GAMES/G12-map/lesson-46-map.svg"),
      durationArr: [0.25,0.6,],
    };
  },
  components: {
    PinyinMoveGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











